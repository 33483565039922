<!-- Banner section starts -->

<div class="hero-banner-bg">
    <div class="container-fluid">
        <div class="row hero-banner-row">
            <div class="col-md-6 hero-banner-div">
                <h1 class="hero-banner-heading">Learn More About the CableFinder Software</h1>
                <p class="hero-banner-text">CableFinder is an industry-first serviceability tool that simplifies the qualifying, quoting and contracting of cable services.</p>
            </div>
        </div>
    </div>
</div>

<!-- Banner section ends -->

<!-- Carriers in our Portfolio section starts -->

<div class="container-fluid carriers-section">
    <div class="row carriers-portfolio-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 carriers-portfolio-heading-div">
            <p class="carriers-portfolio-heading-text">Carriers In Our Portfolio</p>
        </div>
    </div>

    <div class="row carriers-portfolio-logos-row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-1">
            <img class='carrier-logo-img' src="../../../assets/images/logos/comcast-business-logo.png" alt="Comcast Business logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-2">
            <img class='carrier-logo-img carrier-spectrum-logo-img' src="../../../assets/images/logos/Spectrum_Partner_Program_Horiz_RGB.png" alt="Spectrum Business logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-3">
            <img class='carrier-logo-img' src="../../../assets/images/logos/cox-business-logo.png" alt="Cox Business logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-4">
            <img class='carrier-logo-img' src="../../../assets/images/logos/viasat-logo.png" alt="Viasat logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-5">
            <img class='carrier-logo-img' src="../../../assets/images/logos/att.png" alt="AT&T logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-6">
            <img class='carrier-logo-img' src="../../../assets/images/logos/Optimum.png" alt="Optimum logo" />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 carrier-logo-7">
            <img class='carrier-logo-img' src="../../../assets/images/logos/zayo.png" alt="Zayo logo" />
        </div>

    </div>
</div>

<!-- Carriers in our Portfolio section ends -->

<!-- How it works section starts -->

<div class="container-fluid">

    <div class="row how-it-works-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 how-it-works-heading-div">
            <p class="how-it-works-heading-text">How the Tool Works</p>
        </div>
    </div>

    <div class="row how-it-works-subheading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 how-it-works-subheading-div">
            <p class="how-it-works-subheading-text">CableFinder is a revolutionary serviceability tool with automated, self-enabling quote and contract capabilities. The tool collapses disparate systems into a unified application that directly impacts the ease of doing business with a cable
                provider and gives Partners a competitive advantage. Today, CableFinder has helped thousands of users solve a real-world problem for selling cable with over a million searches processed in the last year.
            </p>
        </div>
    </div>

</div>

<!-- How it works section ends -->

<!-- Should be easy section starts -->

<div class="container-fluid">
    <div class="row cf-launch-row">
        <div class="cf-launch-div">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p class="heading">No Really, It Should Be Easy.</p>
                <iframe class="video" width="560" height="315" src="https://youtube.com/embed/ajHA1jOHcfo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>

<!-- Should be easy section ends -->

<!-- Still confused videos section starts -->

<div class="container-fluid still-confused-videos-section">

    <div class="row still-confused-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 still-confused-heading-div">
            <p class="still-confused-heading">Still Confused About CableFinder? Check Out These Videos:</p>
        </div>
    </div>

    <div class="row still-confused-videos-row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 still-confused-videos-div">
            <iframe class="still-confused-videos-iframe" width="560" height="315" src="https://youtube.com/embed/BBbwXSb97tI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 still-confused-videos-div">
            <iframe class="still-confused-videos-iframe" width="560" height="315" src="https://youtube.com/embed/9rhS0JY71jE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 still-confused-videos-div">
            <iframe class="still-confused-videos-iframe" width="560" height="315" src="https://youtube.com/embed/-j51tnmcces" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 still-confused-videos-div">
            <iframe class="still-confused-videos-iframe" width="560" height="315" src="https://youtube.com/embed/_C8jl_AplOE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>

    <div class="row still-confused-text-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 still-confused-text-div">
            <p class="still-confused-text">Sometimes, video can tell a better story. Here at CableFinder, we’ve set out to change how cable business is done. Instead of logging into multiple portals to retrieve serviceability results, sending contracts separately, and creating more
                work than what’s needed, we said - stop the madness. CableFinder built a tool that collapses everything you need, into one, easy-to-use tool. It’s so easy to adopt the software, you won’t need training, and our on-boarding process is seamless.
                We believe all of these things are the reason why so many industry leaders across the channel have already chosen to power their organizations with CableFinder technology.</p>
        </div>
    </div>

</div>

<!-- Still confused videos section ends -->

<!-- Features & Benefits section starts -->

<div class="container-fluid feature-n-benefit-section">

    <div class="row feature-n-benefit-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 feature-n-benefit-div">
            <p class="heading">Features & Benefits to Using CableFinder</p>
        </div>
    </div>

    <div class="row feature-n-benefit-members-row">

        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card feature-n-benefit-card">
            <div class="row feature-n-benefit-card-row">
                <div class="col-3 feature-n-benefit-card-image">
                    <img class="feature-n-benefit-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50EasytoUsePortal.png" alt="Jed-Kenzy" />
                </div>
                <div class="col-9 feature-n-benefit-card-text">
                    <p class="feature-n-benefit-card-text-heading">Easy-to-use Portal</p>
                    <p class="feature-n-benefit-card-sub-text">Collapse multiple systems and portals into one easy-to-use tool.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card feature-n-benefit-card">
            <div class="row feature-n-benefit-card-row">
                <div class="col-3 feature-n-benefit-card-image">
                    <img class="feature-n-benefit-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50APIDriven.png" alt="Mike-Trede" />
                </div>
                <div class="col-9 feature-n-benefit-card-text">
                    <p class="feature-n-benefit-card-text-heading">API Driven for Accuracy</p>
                    <p class="feature-n-benefit-card-sub-text">API driven information, direct from the carriers, as accurate as they are!</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card feature-n-benefit-card">
            <div class="row feature-n-benefit-card-row">
                <div class="col-3 feature-n-benefit-card-image">
                    <img class="feature-n-benefit-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50ContractGeneration.png" alt="Eugene-Atha" />
                </div>
                <div class="col-9 feature-n-benefit-card-text">
                    <p class="feature-n-benefit-card-text-heading">Contract Generation</p>
                    <p class="feature-n-benefit-card-sub-text">Immediate contract generation with built in signature capability.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card feature-n-benefit-card">
            <div class="row feature-n-benefit-card-row">
                <div class="col-3 feature-n-benefit-card-image">
                    <img class="feature-n-benefit-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50CoaxandFiberResults.png" alt="Jeff-Pratt" />
                </div>
                <div class="col-9 feature-n-benefit-card-text">
                    <p class="feature-n-benefit-card-text-heading">Coax and Fiber Results!</p>
                    <p class="feature-n-benefit-card-sub-text">Serviceability results for hundreds of addresses in minutes for both Coax and Fiber.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card feature-n-benefit-card">
            <div class="row feature-n-benefit-card-row">
                <div class="col-3 feature-n-benefit-card-image">
                    <img class="feature-n-benefit-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50GooglePlaces.png" alt="Mathew-Marshall" />
                </div>
                <div class="col-9 feature-n-benefit-card-text">
                    <p class="feature-n-benefit-card-text-heading">Integrated With Google Places</p>
                    <p class="feature-n-benefit-card-sub-text">CableFinder is integrated with Google Places, which allows you to be able to search any U.S. postal address in our tool.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card feature-n-benefit-card">
            <div class="row feature-n-benefit-card-row">
                <div class="col-3 feature-n-benefit-card-image">
                    <img class="feature-n-benefit-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50DocuSign.png" alt="Nicole-Heinz" />
                </div>
                <div class="col-9 feature-n-benefit-card-text">
                    <p class="feature-n-benefit-card-text-heading">DocuSign Enabled</p>
                    <p class="feature-n-benefit-card-sub-text">The software is specifically integrated with DocuSign technology, which is why you can contract directly from the tool!</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card feature-n-benefit-card">
            <div class="row feature-n-benefit-card-row">
                <div class="col-3 feature-n-benefit-card-image">
                    <img class="feature-n-benefit-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50TakeAnywhereWithYou.png" alt="Mathew-Marshall" />
                </div>
                <div class="col-9 feature-n-benefit-card-text">
                    <p class="feature-n-benefit-card-text-heading">Take it Anywhere!</p>
                    <p class="feature-n-benefit-card-sub-text">Login to the CableFinder application anywhere, on any device! Mobile, tablet, laptop, and desktop are all available to you.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card feature-n-benefit-card">
            <div class="row feature-n-benefit-card-row">
                <div class="col-3 feature-n-benefit-card-image">
                    <img class="feature-n-benefit-member-image img-responsive" src="../../../assets/images/feature-and-benefit/50x50QuicklyAtTheClickOfAButton.png" alt="Nicole-Heinz" />
                </div>
                <div class="col-9 feature-n-benefit-card-text">
                    <p class="feature-n-benefit-card-text-heading">At the Click of a Button</p>
                    <p class="feature-n-benefit-card-sub-text">Serviceability to signature in sixty seconds!With just a few clicks, you can qualify, quote, and contract cable deals.</p>
                </div>
            </div>
        </div>

    </div>

</div>

<!-- Features & Benefits section ends -->

<!-- Industry leaders section starts -->

<div class="container-fluid industry-leaders-section">

    <div class="industry-leaders-row">

        <div class="row industry-leaders-heading-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 industry-leaders-div">
                <p class="heading">Trusted By Industry Leaders</p>
            </div>
        </div>

        <div class="industry-leaders-testimonial-row">
            <app-cf-testimonial-carousel></app-cf-testimonial-carousel>
        </div>

    </div>

    <div class="industry-leaders-logos-row">

        <div class="row justify-content-around">
            <div class="logo-1">
              <a href="https://appdirect.cablefinder.net"><img width="140px" height="auto" class="agent-logo app-direct" src="../../../assets/images/logos/appdirect-jogo.png" alt="AppDirect logo" /></a>
            </div>
            <div class="logo-2">
              <a href="https://avant.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/avant-logo.png" alt="Avant logo" /></a>
            </div>
            <div class="logo-3">
              <a href="https://innovative.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/innovative-logo-agent.png" alt="Innovative Business Solutions logo" /></a>
            </div>
            <div class="logo-4">
              <a href="https://intelisys.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/intelisys-logo.png" alt="Intelisys logo" /></a>
            </div>
            <div class="logo-5">
                <a href="https://intelisys.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/pax8-logo.png" alt="Pax8 logo" /></a>
              </div>
            </div>
            <div class="logo-6">
                <a href="https://telarus.cablefinder.net"><img width="140px" height="auto" class="agent-logo" src="../../../assets/images/logos/telatus-logo.png" alt="telatus logo" /></a>
            </div>
          </div>
      <!-- <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 logo-1"></div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 logo-2">
          <a href="https://loc8.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/pax8-logo.png" alt="Pax8 logo" /></a>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 logo-3">
          <a href="https://tbi.cablefinder.net"><img class="agent-logo" src="../../../assets/images/logos/tbi-logo.png" alt="TBI logo" /></a>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 logo-4"></div>
      </div> -->

</div>

<!-- Industry leaders section ends-->

<!-- Form section starts-->

<div class="form-section-bg">
    <div class="container-fluid">
        <div class="row form-heading-row">
            <div class="col-12 form-heading-div">
                <h3 class="form-heading-text">Need to Get In Touch With Our Team?</h3>
                <p class="form-sub-heading-text">Use the form below to have a member from the CableFinder team contact you directly</p>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-12 form-div">
                <app-cf-register-demo-day-form></app-cf-register-demo-day-form>
            </div>
        </div>
    </div>
</div>

<!-- Form section ends -->

<!-- Executive Team section starts -->

<div class="container-fluid executive-team-section">

    <div class="row executive-team-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 executive-team-div">
            <p class="heading">Our Executive Team</p>
        </div>
    </div>

    <div class="row executive-team-members-row">

        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Jed-Kenzy.png" alt="Jed-Kenzy" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Jed Kenzy</p>
                    <p class="executive-team-card-sub-text">Co-Founder of CableFinder</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Mike-Trede.png" alt="Mike-Trede" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Mike Trede</p>
                    <p class="executive-team-card-sub-text">Co-Founder of CableFinder</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Dan-Stowe.png" alt="Dan Stowe" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Dan Stowe</p>
                    <p class="executive-team-card-sub-text">Senior Director of Engineering at CableFinder</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Jeff-Pratt.png" alt="Jeff-Pratt" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Jeff Pratt</p>
                    <p class="executive-team-card-sub-text">Vice President of Strategic Alliances at CableFinder</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Mathew-Marshall.png" alt="Mathew-Marshall" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Mathew Marshall</p>
                    <p class="executive-team-card-sub-text">Senior Director of Product at CableFinder</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Happy-Marino.png" alt="Happy-Marino" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Happy Marino</p>
                    <p class="executive-team-card-sub-text">Senior Director of Marketing at CableFinder</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Taylor-Brown-Headshot-Circle.png" alt="Taylor-Brown" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Taylor Brown</p>
                    <p class="executive-team-card-sub-text">Sr. Account Manager & Brand Ambassador at CableFinder</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Juani-Hopwood.jpeg" alt="Juani-Hopwood" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Juani Hopwood</p>
                    <p class="executive-team-card-sub-text">Digital Marketing Manager at CableFinder</p>
                </div>
            </div>
        </div>
        <div class="col-xs-11 col-sm-11 col-md-11 col-lg-5 card executive-team-card">
            <div class="row executive-team-card-row">
                <div class="col-3 executive-team-card-image">
                    <img class="executive-team-member-image img-responsive" src="../../../assets/images/executive-team/Kelsey-Zwergel.jpeg" alt="Kelsey-Zwergel" />
                </div>
                <div class="col-9 executive-team-card-text">
                    <p class="executive-team-card-text-heading">Kelsey Zwergel</p>
                    <p class="executive-team-card-sub-text">Technical Support Specialist at CableFinder</p>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Executive Team section ends -->
